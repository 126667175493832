@import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");

.App {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  align-items: flex-end;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #050716;
  background-image: url("../src/components/homeapple.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  font-family: "Orbitron", sans-serif;
}

.canvas {
  display: flex;
  position: absolute;
  height: 100vh;
  width: 100vw;
  /* vertical-align: bottom;
  width: 100%;
  height: 100%; */
}

a {
  text-decoration: none;
  color: white;
}

.contact-me,
.about-me,
.projects {
  position: absolute;
  color: white;
  align-self: center;
}
