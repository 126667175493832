.projects-main-div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: aliceblue;
  position: absolute;
  align-self: center;
  left: 5%;
  height: 100vh;
  width: 60%;
  margin-right: 2%;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.05);
  min-width: 350px;
}

.projects-div {
  opacity: 0.6;
  width: 100%;
  height: 80%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-width: 300px;
}

.a-links {
  display: flex;
  width: 25%;
  height: 25%;
  margin: 20px;
  border-radius: 5px;
  padding: 0px;
  min-width: 200px;
  background-size: cover;
  background-position: center;
}

.a-links p {
  display: none;
}

.a-links:hover > p {
  border-radius: 5px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: rgb(138, 166, 226);
}

.a-links:hover {
  background-color: rgb(36, 90, 90);
  transition: all 1s ease-in-out;
  display: flex;
  justify-content: center;
  flex-direction: column;
  scale: 1.05;
  transition: all 0.5s ease-in-out;
  background: none;
  position: relative;
  z-index: 5;
}

#project1 {
  background-image: url("/src/Images/NatureAll.png");
}
#project2 {
  background-image: url("/src/Images/NutriYoga.png");
}
#project3 {
  background-image: url("/src/Images/Grandmas.png");
}
#project4 {
  background-image: url("/src/Images/Reggie.png");
}
#project5 {
  background-image: url("/src/Images/Ars.png");
}
#project6 {
  background-image: url("/src/Images/Kita.png");
}
#project7 {
  background-image: url("/src/Images/Church.png");
}
#project8 {
  background-image: url("/src/Images/Gianni.png");
}

.projects-div img {
  width: 100%;
  margin: 0;
  border-radius: 5px;
  background-color: rgb(66, 54, 170);
}

.buttons-div-projects {
  margin-bottom: -5%;
  display: flex;
  justify-content: space-between;
  height: 15%;
  width: 26%;
  padding-left: 75px;
  min-width: 270px;
  margin-right: 10px;
}

.buttons-div-projects a {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 130px;
  height: 60px;
  border: 1px solid white;
  font-weight: 600;
  border-radius: 5px;
  font-size: 20px;
}

.motion-button1-projects {
  height: 60px;
  border-radius: 5px;
}

.motion-button2-projects {
  height: 60px;
  border-radius: 5px;
}

@media (max-width: 1200px) {
  .buttons-div-projects {
    margin-top: 10px;
    margin-left: -15px;
    display: flex;
    justify-content: space-between;
    height: 20%;
    padding-left: 75px;
    min-width: 270px;
    margin-right: 10px;
  }

  .projects-div .a-links {
    display: flex;
    width: 40%;
    height: 20%;
    margin: 20px;
    border-radius: 5px;
    /* padding: 0px; */
    /* min-width: 200px; */
  }
  .buttons-div-projects {
    margin-bottom: -5%;
    display: flex;
    justify-content: space-between;
    height: 10%;
    padding-left: 75px;
    max-width: 300px;
    margin-right: 10px;
  }
}

@media (max-width: 850px) {
  .buttons-div-projects a {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 130px;
    height: 60px;
    border: 1px solid white;
    font-weight: 600;
    border-radius: 5px;
    font-size: 20px;
  }
  .buttons-div-projects {
    margin-bottom: -5%;
    display: flex;
    justify-content: space-between;
    height: 15%;
    width: 55%;
    padding-left: 75px;
    max-width: 300px;
    margin-right: 10px;
  }

  .projects-div .a-links {
    display: flex;
    width: 50%;
    height: 10%;
    margin: 5px;
    border-radius: 5px;
    /* padding: 0px; */
    /* min-width: 200px; */
  }
  /* .buttons-div-projects a {
    display: none;
  } */

  .projects-div {
    width: 90%;
    height: 100%;
    margin-top: 40px;
    margin-left: 38px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
  }
  .projects-main-div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: aliceblue;
    position: absolute;
    align-self: center;
    left: -5%;
    height: 100vh;
    width: 70%;
    margin-right: 2%;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.05);
    min-width: 400px;
  }
}

@media (max-width: 650px) {
  .buttons-div-projects {
    margin-bottom: -5%;
    display: flex;
    justify-content: space-between;
    height: 15%;
    width: 55%;
    padding-left: 75px;
    max-width: 300px;
    margin-right: 10px;
  }
  .projects-main-div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: aliceblue;
    position: absolute;
    align-self: center;
    left: -5%;
    height: 100vh;
    width: 70%;
    margin-right: 2%;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.05);
    min-width: 350px;
  }
}

@media (max-width: 430px) {
  .projects-main-div {
    margin-top: -40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: aliceblue;
    position: absolute;
    align-self: center;
    left: -15%;
    height: 105vh;
    width: 30%;
    margin-right: 2%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.05);
    min-width: 380px;
  }
}

@media (max-width: 414px) {
  .projects-main-div {
    margin-top: -50px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: aliceblue;
    position: absolute;
    align-self: center;
    left: -20%;
    height: 105vh;
    width: 30%;
    margin-right: 2%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.05);
    min-width: 380px;
  }
  .motion-button1-projects {
    height: 40px;
    border-radius: 5px;
  }
  .motion-button2-projects {
    height: 40px;
    border-radius: 5px;
  }
  .buttons-div-projects {
    margin-bottom: 15%;
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
    height: 10%;
    padding-left: 75px;
    min-width: 25px;
    margin-right: 10px;
  }
  .buttons-div-projects a {
    margin-left: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 80px;
    height: 40px;
    border: 1px solid white;
    font-weight: 600;
    border-radius: 5px;
    font-size: 15px;
  }
  .projects-div {
    opacity: 0.6;
    width: 100%;
    height: 70%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-width: 300px;
  }
}

@media (max-width: 360px) {
  .projects-main-div {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: aliceblue;
    position: absolute;
    align-self: center;
    left: -25%;
    height: 105vh;
    width: 30%;
    margin-right: 2%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.05);
    min-width: 380px;
  }
  .motion-button1-projects {
    height: 40px;
    border-radius: 5px;
  }
  .motion-button2-projects {
    height: 40px;
    border-radius: 5px;
  }
  .buttons-div-projects {
    margin-bottom: 15%;
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
    height: 10%;
    padding-left: 75px;
    min-width: 25px;
    margin-right: 10px;
  }
  .buttons-div-projects a {
    margin-left: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 80px;
    height: 40px;
    border: 1px solid white;
    font-weight: 600;
    border-radius: 5px;
    font-size: 15px;
  }
  .projects-div {
    opacity: 0.6;
    width: 100%;
    height: 70%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-width: 300px;
  }
}
