.burger {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(241, 221, 221, 0.001);
  position: fixed;
  color: white;
  align-self: flex-end;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 1;
}

.burger-links {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 300px;
  top: 105px;
  width: 112px;
  z-index: 1;
  font-size: 30px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 0 0px 0 6px;
}

nav {
  display: flex;
  justify-content: flex-end;
  height: 150px;
  width: 160px;
}

.menu-item {
  font-weight: bolder;
  text-decoration: none;
  color: rgb(255, 255, 255);
  margin-left: 5px;
}

.burger-links div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  width: 250%;
  height: 80px;
  z-index: -5;
}

.links-name {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;
  background-color: rgba(255, 255, 255, 0.05);
  width: 95px;
  height: 47px;
  position: absolute;
  z-index: -10;
  opacity: 0;
  border-radius: 6px 0 0 6px;
}
.burger-links p {
  margin-bottom: 18px;
}

.burger-links a:hover {
  color: rgb(117, 116, 126);
  transition: all 0.01s;
}

.burger-links div:hover > .links-name {
  opacity: 1;
  transform: translateX(-109%);
  transition: all 0.3s ease-in;
}

#menuToggle {
  width: 70%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menuToggle:hover {
  background-color: rgba#87ad98;
}

#menuToggle input {
  display: flex;
  width: 90px;
  height: 90px;
  position: absolute;
  cursor: pointer;
  opacity: 0; /* hide input */
  z-index: 1; /* top of the menu */
}

#menuToggle span {
  width: 40%;
  height: 3px;
  margin-bottom: 10px;
  background: #7f8fa6;
  border-radius: 6px;
  -webkit-transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
  -moz-transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
  -ms-transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
  -o-transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
  transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
}

#spanx1 {
  background-color: #36497a;
  transform: rotate(45deg) translateX(15px) translateY(9px);
}
#spanx2 {
  background-color: #36497a;
  transform: rotate(495deg) translateX(1px) translateY(-4px);
}
#spanx3 {
  background-color: #36497a;
  transform: rotate(45deg);
  opacity: 0;
}

@media (max-width: 600px) {
  .burger-links div {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    width: -100%;
    height: 80px;
    z-index: -5;
  }
  .links-name {
    display: none;
  }
}

@media (max-width: 500px) {
  .burger-links div {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    z-index: -5;
  }
}

@media (max-width: 440px) {
  .burger-links div {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 80px;
    z-index: -5;
  }
  .burger-links {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 300px;
    top: 60px;
    width: 56px;
    z-index: 1;
    font-size: 25px;
    background-color: rgba(241, 221, 221, 0.05);
    border-radius: 0 0px 0 6px;
  }
  #menuToggle {
    width: 35%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #spanx1 {
    background-color: #36497a;
    transform: rotate(45deg) translateX(8px) translateY(6px);
  }
  #spanx2 {
    background-color: #36497a;
    transform: rotate(495deg) translateX(1px) translateY(-2px);
  }
  #spanx3 {
    background-color: #36497a;
    transform: rotate(45deg);
    opacity: 0;
  }

  #menuToggle span {
    width: 50%;
    height: 2px;
    margin-bottom: 5px;
    background: #7f8fa6;
    border-radius: 6px;
    -webkit-transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
    -moz-transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
    -ms-transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
    -o-transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
    transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
  }
}
