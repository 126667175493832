.main-div {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  font-size: 30px;
  color: rgb(226, 231, 236);
  position: absolute;
  align-self: center;
  left: 5%;
  height: 100vh;
  width: 40%;
  margin-right: 2%;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  min-width: 300px;
}

.home {
  font-size: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  margin-bottom: 5%;
  position: relative;
  color: rgb(255, 255, 255);
  width: 95%;
}

.buttons-div {
  margin-left: 10px;
  margin-bottom: 30%;
  display: flex;
  justify-content: space-between;
  height: 8%;
  padding-left: 10px;
  max-width: 300px;
  margin-right: 10px;
}

.buttons-div a {
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 5;
  transition: color 150ms ease-in-out;
  width: 130px;
  height: 60px;
  border: 1px solid white;
  font-weight: 600;
  border-radius: 5px;
  font-size: 20px;
}

.motion-button1 {
  height: 60px;
  border-radius: 5px;
}

.motion-button2 {
  height: 60px;
  border-radius: 5px;
}

.home h1,
.home h2,
.home p {
  padding: 10px;
  margin: 0;
}

@media (max-width: 430px) {
  .main-div {
    padding-top: 160px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    font-size: 15px;
    color: rgb(226, 231, 236);
    position: absolute;
    align-self: center;
    left: 5%;
    height: 100vh;
    width: 40%;
    margin-right: 2%;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    min-width: 300px;
    margin-top: -100px;
  }

  .home {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    margin-bottom: 5%;
    position: relative;
    color: rgb(255, 255, 255);
    width: 95%;
  }
}

@media (max-width: 414px) {
  .main-div {
    padding-top: 160px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    font-size: 15px;
    color: rgb(226, 231, 236);
    position: absolute;
    align-self: center;
    left: 5%;
    height: 100vh;
    width: 40%;
    margin-right: 2%;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    min-width: 300px;
  }
  .home {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    margin-bottom: 5%;
    position: relative;
    color: rgb(255, 255, 255);
    width: 95%;
  }
}

@media (max-width: 375px) {
  .main-div {
    padding-top: 105px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    font-size: 15px;
    color: rgb(226, 231, 236);
    position: absolute;
    align-self: center;
    left: 5%;
    height: 100vh;
    width: 40%;
    margin-right: 2%;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    min-width: 300px;
  }
  .home {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    margin-bottom: 5%;
    position: relative;
    color: rgb(255, 255, 255);
    width: 95%;
  }
}

@media (max-width: 360px) {
  .main-div {
    padding-top: 105px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    font-size: 15px;
    color: rgb(226, 231, 236);
    position: absolute;
    align-self: center;
    left: 1%;
    height: 100vh;
    width: 40%;
    margin-right: 2%;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    min-width: 300px;
  }
  .home {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5px;
    margin-bottom: 15%;
    position: relative;
    color: rgb(255, 255, 255);
    width: 95%;
  }
  .buttons-div {
    margin-left: 10px;
    margin-bottom: 50%;
    display: flex;
    justify-content: space-between;
    height: 8%;
    padding-left: 10px;
    max-width: 300px;
    margin-right: 10px;
    margin-left: 5px;
  }
}
