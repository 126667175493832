.main-div-about-me {
  display: flex;
  flex-direction: column;
  justify-content: end;
  font-size: 20px;
  color: aliceblue;
  position: absolute;
  align-self: center;
  left: 5%;
  height: 100vh;
  width: 50%;
  margin-right: 2%;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.05);
  min-width: 350px;
  padding-top: 70px;
}
.aboutme-div p {
  margin-left: 8px;
  padding: 0;
}

.tools {
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
}

.tools p {
  margin-left: 5px;
  font-weight: bold;
  color: rgb(95, 244, 255);
}

.tools h3 {
  margin-left: 5px;
}

.aboutme-div {
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-bottom: 40px;
}

.get-in-touch {
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding-top: 50px;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.2);
}
.get-in-touch h3 {
  margin-top: -45px;
  margin-left: 5px;
}

.links {
  display: flex;
  justify-content: space-between;
  padding: 0;
  height: 30px;
  width: 60%;
  font-weight: bolder;
}

.links a {
  color: rgb(95, 244, 255);
  margin-left: 5px;
}

.links a:hover {
  color: white;
}

.buttons-about-div {
  display: flex;
  justify-content: space-between;
  height: 8%;
  max-width: 50%;
  padding-left: 16px;
  margin-left: 1%;
  margin-bottom: 150px;
  max-width: 300px;
  margin-right: 10px;
}

.buttons-about-div a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 130px;
  height: 60px;
  border: 1px solid white;
  font-weight: 600;
  border-radius: 5px;
  font-size: 20px;
}

.button1 {
  border-radius: 5px;
  height: 60px;
}

.button2 {
  border-radius: 5px;
  height: 60px;
}

@media (max-width: 850px) {
  .main-div-about-me {
    display: flex;
    flex-direction: column;
    justify-content: end;
    font-size: 18px;
    color: aliceblue;
    position: absolute;
    align-self: center;
    left: 0%;
    height: 100vh;
    width: 39%;
    margin-right: 5%;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.05);
    min-width: 330px;
  }
  .buttons-about-div {
    display: flex;
    justify-content: space-between;
    height: 8%;
    max-width: 50%;
    padding-left: 10px;
    margin-left: 1%;
    margin-bottom: 150px;
    max-width: 270px;
    margin-right: 10px;
  }
}

@media (max-width: 430px) {
  .main-div-about-me {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    font-size: 15px;
    color: aliceblue;
    position: absolute;
    align-self: center;
    left: 5%;
    height: 100vh;
    width: 20%;
    margin-right: 3%;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.05);
    min-width: 300px;
  }
}

@media (max-width: 375px) {
  .main-div-about-me {
    padding-top: 0px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    font-size: 13px;
    color: aliceblue;
    position: absolute;
    align-self: center;
    left: 5%;
    height: 100vh;
    width: 20%;
    margin-right: 3%;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.05);
    min-width: 300px;
  }
}

@media (max-width: 375px) {
  .main-div-about-me {
    padding-top: 100px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    font-size: 13px;
    color: aliceblue;
    position: absolute;
    align-self: center;
    left: 1%;
    height: 100vh;
    width: 20%;
    margin-right: 3%;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.05);
    min-width: 300px;
  }
}
