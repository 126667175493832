.contact-me-main-div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 20px;
  color: aliceblue;
  position: absolute;
  align-self: center;
  left: 5%;
  height: 100vh;
  width: 50%;
  margin-right: 2%;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.05);
  min-width: 350px;
}

.h1-text {
  /* background-color: rgb(107, 141, 141); */
  width: 100%;
  height: 10%;
  display: flex;
  top: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inputs-div {
  /* background-color: brown; */
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 50%;
}

.inputs-div input {
  background-color: rgba(255, 255, 255, 0.05);
  color: white;
  border-radius: 5px;
  border: 1px solid white;
  /* background-color: chartreuse; */
  min-width: 300px;
  height: 30px;
  padding: 5px;
}

.inputs-div textarea {
  background-color: rgba(255, 255, 255, 0.05);
  color: white;
  border-radius: 5px;
  border: 1px solid white;
  /* background-color: chartreuse; */
  height: 150px;
  width: 50%;
  min-width: 300px;
  padding: 5px;
  resize: none;
}

.submit-button {
  margin-top: 50px;
}

.button-div {
  align-self: center;
}

#contact-button {
  all: unset;
  color: white;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  width: 100px;
  height: 40px;
  border: 1px solid white;
  font-weight: 200;
  border-radius: 5px;
  font-size: 20px;
}

#contact-button:hover {
  cursor: pointer;
}

.button-div a {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  width: 130px;
  height: 60px;
  border: 1px solid white;
  font-weight: 600;
  border-radius: 5px;
  font-size: 20px;
}

button1-projects {
  height: 20px;
  border-radius: 5px;
}

@media (max-width: 650px) {
  .contact-me-main-div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 20px;
    color: aliceblue;
    position: absolute;
    align-self: center;
    left: -1.7%;
    height: 100vh;
    width: 10%;
    margin-right: -10%;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.05);
    min-width: 350px;
  }

  .h1-text {
    /* background-color: rgb(107, 141, 141); */
    width: 100%;
    height: 10%;
    display: flex;
    top: 0;
    margin-left: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 440px) {
  .contact-me-main-div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 20px;
    color: aliceblue;
    position: absolute;
    align-self: center;
    left: 5%;
    height: 100vh;
    width: 30%;
    margin-right: -5%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.05);
    min-width: 280px;
  }
  .h1-text {
    /* background-color: rgb(107, 141, 141); */
    width: 70%;
    height: 10%;
    display: flex;
    top: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .inputs-div {
    /* background-color: brown; */
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 50%;
  }
  .inputs-div input {
    background-color: rgba(255, 255, 255, 0.05);
    color: white;
    border-radius: 5px;
    border: 1px solid white;
    /* background-color: chartreuse; */
    min-width: 240px;
    height: 30px;
    padding: 5px;
  }
  .inputs-div textarea {
    background-color: rgba(255, 255, 255, 0.05);
    color: white;
    border-radius: 5px;
    border: 1px solid white;
    /* background-color: chartreuse; */
    height: 100px;
    width: 50%;
    min-width: 240px;
    padding: 5px;
    resize: none;
  }
  .contact-me-main-div {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 15px;
    color: aliceblue;
    position: absolute;
    align-self: center;
    left: 5%;
    height: 100vh;
    width: 50%;
    margin-right: 2%;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.05);
    min-width: 300px;
  }
  .button-div a {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    width: 120px;
    height: 40px;
    border: 1px solid white;
    font-weight: 600;
    border-radius: 5px;
    font-size: 20px;
    margin-bottom: 140px;
  }
}

@media (max-width: 360px) {
  .contact-me-main-div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 15px;
    color: aliceblue;
    position: absolute;
    align-self: center;
    left: 5%;
    height: 110vh;
    width: 50%;
    margin-right: 2%;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.05);
    min-width: 280px;
  }
  .h1-text {
    /* background-color: rgb(107, 141, 141); */
    width: 90%;
    height: 10%;
    display: flex;
    top: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .inputs-div {
    /* background-color: brown; */
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 50%;
  }
  .inputs-div input {
    background-color: rgba(255, 255, 255, 0.05);
    color: white;
    border-radius: 5px;
    border: 1px solid white;
    /* background-color: chartreuse; */
    min-width: 240px;
    height: 30px;
    padding: 5px;
  }
  .inputs-div textarea {
    background-color: rgba(255, 255, 255, 0.05);
    color: white;
    border-radius: 5px;
    border: 1px solid white;
    /* background-color: chartreuse; */
    height: 100px;
    width: 50%;
    min-width: 240px;
    padding: 5px;
    resize: none;
  }

  .button-div a {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    width: 120px;
    height: 40px;
    border: 1px solid white;
    font-weight: 600;
    border-radius: 5px;
    font-size: 20px;
    margin-bottom: 140px;
  }
}
